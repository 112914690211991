export const GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST =
	'GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST';
export const GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST_SUCCESS =
	'GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST_SUCCESS';
export const GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST_FAILURE =
	'GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST_FAILURE';

export const GET_CMMC_DOMAIN_MAPPING_REQUEST =
	'GET_CMMC_DOMAIN_MAPPING_REQUEST';
export const GET_CMMC_DOMAIN_MAPPING_REQUEST_SUCCESS =
	'GET_CMMC_DOMAIN_MAPPING_REQUEST_SUCCESS';
export const GET_CMMC_DOMAIN_MAPPING_REQUEST_FAILURE =
	'GET_CMMC_DOMAIN_MAPPING_REQUEST_FAILURE';


export const GET_BUDGET_DOMAIN_MAPPING_REQUEST =
	'GET_BUDGET_DOMAIN_MAPPING_REQUEST';
export const GET_BUDGET_DOMAIN_MAPPING_REQUEST_SUCCESS =
	'GET_BUDGET_DOMAIN_MAPPING_REQUEST_SUCCESS';
export const GET_BUDGET_DOMAIN_MAPPING_REQUEST_FAILURE =
	'GET_BUDGET_DOMAIN_MAPPING_REQUEST_FAILURE';

export const GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST =
	'GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST';
export const GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST_SUCCESS =
	'GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST_SUCCESS';
export const GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST_FAILURE =
	'GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST_FAILURE';


export const GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST =
	'GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST';
export const GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST_SUCCESS =
	'GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST_SUCCESS';
export const GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST_FAILURE =
	'GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST_FAILURE';
