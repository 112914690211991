export const GET_CMMC_DATA_POINTS_REQUEST = 'GET_CMMC_DATA_POINTS_REQUEST';
export const GET_CMMC_DATA_POINTS_REQUEST_SUCCESS =
  'GET_CMMC_DATA_POINTS_REQUEST_SUCCESS';
export const GET_CMMC_DATA_POINTS_REQUEST_FAILURE =
  'GET_CMMC_DATA_POINTS_REQUEST_FAILURE';

export const GET_CMMC_TABLE_DATA_REQUEST = 'GET_CMMC_TABLE_DATA_REQUEST';
export const GET_CMMC_TABLE_DATA_REQUEST_SUCCESS =
  'GET_CMMC_TABLE_DATA_REQUEST_SUCCESS';
export const GET_CMMC_TABLE_DATA_REQUEST_FAILURE =
  'GET_CMMC_TABLE_DATA_REQUEST_FAILURE';

export const UPDATE_CMMC_DATA_POINTS_REQUEST = 'UPDATE_CMMC_DATA_POINTS_REQUEST';
export const UPDATE_CMMC_DATA_POINTS_REQUEST_SUCCESS =
  'UPDATE_CMMC_DATA_POINTS_REQUEST_SUCCESS';
export const UPDATE_CMMC_DATA_POINTS_REQUEST_FAILURE =
  'UPDATE_CMMC_DATA_POINTS_REQUEST_FAILURE';
export const UPDATE_DATAPOINTSDENOMINATOR_DATA =
  'UPDATE_DATAPOINTSDENOMINATOR_DATA';
export const UPDATE_DATAPOINTSNUMERATOR_DATA =
  'UPDATE_DATAPOINTSNUMERATOR_DATA';
export const UPDATE_CMMC_DATAPOINTS_DATA = 'UPDATE_CMMC_DATAPOINTS_DATA';

