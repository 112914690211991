import {
    GET_CMMC_DATA_POINTS_REQUEST,
    GET_CMMC_DATA_POINTS_REQUEST_SUCCESS,
    GET_CMMC_DATA_POINTS_REQUEST_FAILURE,
    GET_CMMC_TABLE_DATA_REQUEST,
    GET_CMMC_TABLE_DATA_REQUEST_FAILURE,
    GET_CMMC_TABLE_DATA_REQUEST_SUCCESS,
    UPDATE_CMMC_DATA_POINTS_REQUEST,
    UPDATE_CMMC_DATA_POINTS_REQUEST_SUCCESS,
    UPDATE_CMMC_DATA_POINTS_REQUEST_FAILURE,
    // UPDATE_DATAPOINTSDENOMINATOR_DATA,
    // UPDATE_DATAPOINTSNUMERATOR_DATA,
    UPDATE_CMMC_DATAPOINTS_DATA,
  } from './actionTypes';
  
  const initialState = {
    data: {},
    isLoading: false,
    error: undefined,
  };
  
  export const CMMCDataPointsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CMMC_DATA_POINTS_REQUEST_SUCCESS: {
        const { data } = action;
  
        return {
          ...state,
          data: data,
          isLoading: false,
        };
      }
  
      case GET_CMMC_DATA_POINTS_REQUEST_FAILURE: {
        const { msg: error } = action;
        return {
          ...state,
          error,
          isLoading: false,
        };
      }
  
      case GET_CMMC_DATA_POINTS_REQUEST: {
        return {
          ...state,
          isLoading: true,
        };
      }
  
      default:
        return {
          ...state,
        };
    }
  };
  
  const cmmcTableInitialState = {
    data: [],
    isLoading: false,
    error: undefined,
    isCMMCDataPoint: false,
  };
  
  export const CMMCTableDataReducer = (
    state = cmmcTableInitialState,
    action,
  ) => {
    switch (action.type) {
      case GET_CMMC_TABLE_DATA_REQUEST_SUCCESS: {
        const { data } = action;
  
        return {
          ...state,
          data: data,
          isLoading: false,
          isCMMCDataPoint: false,
        };
      }
  
      case GET_CMMC_TABLE_DATA_REQUEST_FAILURE: {
        const { msg: error } = action;
        return {
          ...state,
          error,
          isLoading: false,
          isCMMCDataPoint: false,
        };
      }
  
      case GET_CMMC_TABLE_DATA_REQUEST: {
        return {
          ...state,
          isLoading: true,
          isCMMCDataPoint: true,
        };
      }
    //   case UPDATE_DATAPOINTSDENOMINATOR_DATA: {
    //     const { data } = action;
    //     return {
    //       ...state,
    //       data: {
    //         ...state.data,
    //         dataPointsDenominator: data,
    //       },
    //     };
    //   }
      case UPDATE_CMMC_DATAPOINTS_DATA: {
        const { data } = action;
        return {
          ...state,
          data: {
            ...state.data,
            dataPointData: { ...state.data.dataPointData, ...data },
          },
        };
      }
    //   case UPDATE_DATAPOINTSNUMERATOR_DATA: {
    //     const { data } = action;
  
    //     return {
    //       ...state,
    //       data: {
    //         ...state.data,
    //         dataPointsNumerator: data,
    //       },
    //     };
    //   }
      default:
        return {
          ...state,
        };
    }
  };
  
  const dataPointInitialsState = {
    data: [],
    isLoading: false,
    error: undefined,
  };
  
  export const UpdateCMMCDataPointsReducer = (
    state = dataPointInitialsState,
    action,
  ) => {
    switch (action.type) {
      case UPDATE_CMMC_DATA_POINTS_REQUEST_SUCCESS: {
        const { data } = action;
  
        return {
          ...state,
          data: data,
          isLoading: false,
        };
      }
  
      case UPDATE_CMMC_DATA_POINTS_REQUEST_FAILURE: {
        const { msg: error } = action;
        return {
          ...state,
          error,
          isLoading: false,
        };
      }
  
      case UPDATE_CMMC_DATA_POINTS_REQUEST: {
        return {
          ...state,
          isLoading: true,
        };
      }
  
      default:
        return {
          ...state,
        };
    }
  };
  