import {
	GET_BUDGET_DOMAIN_MAPPING_REQUEST,
	GET_BUDGET_DOMAIN_MAPPING_REQUEST_FAILURE,
	GET_BUDGET_DOMAIN_MAPPING_REQUEST_SUCCESS,
	GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST,
	GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST_FAILURE,
	GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST_SUCCESS,
	GET_CMMC_DOMAIN_MAPPING_REQUEST,
	GET_CMMC_DOMAIN_MAPPING_REQUEST_FAILURE,
	GET_CMMC_DOMAIN_MAPPING_REQUEST_SUCCESS,
	GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST,
	GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST_FAILURE,
	GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST_SUCCESS,
	GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST,
	GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST_FAILURE,
	GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST_SUCCESS,
} from './actionTypes';

const initialState = {
	cmmcDomain: undefined,
	cyberRiskDomain: undefined,
	budgetProgramDomain: undefined,
	insiderDomain: undefined,
	connectionDatapoint: undefined,
	isLoading: false,
	error: undefined,
};

export const domainMappingReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_BUDGET_DOMAIN_MAPPING_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case GET_CMMC_DOMAIN_MAPPING_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case GET_BUDGET_DOMAIN_MAPPING_REQUEST_SUCCESS: {
			const { data } = action;
			return {
				...state,
				isLoading: false,
				budgetProgramDomain: data,
			};
		}
		case GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST_SUCCESS: {
			const { data } = action;
			return {
				...state,
				isLoading: false,
				cyberRiskDomain: data,
			};
		}
		case GET_CMMC_DOMAIN_MAPPING_REQUEST_SUCCESS: {
			const { data } = action;
			return {
				...state,
				isLoading: false,
				cmmcDomain: data,
			};
		}
		case GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST_SUCCESS: {
			const { data } = action;
			return {
				...state,
				isLoading: false,
				insiderDomain: data,
			};
		}
		case GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST_SUCCESS: {
			const { data } = action;
			return {
				...state,
				isLoading: false,
				connectionDatapoint: data,
			};
		}
		case GET_CONNECTION_DATAPOINT_DOMAIN_MAPPING_REQUEST_FAILURE: {
			const { msg } = action;
			return {
				...state,
				isLoading: false,
				error: msg,
			};
		}
		case GET_BUDGET_DOMAIN_MAPPING_REQUEST_FAILURE: {
			const { msg } = action;
			return {
				...state,
				isLoading: false,
				error: msg,
			};
		}
		case GET_CYBER_RISK_DOMAIN_MAPPING_REQUEST_FAILURE: {
			const { msg } = action;
			return {
				...state,
				isLoading: false,
				error: msg,
			};
		}
		case GET_CMMC_DOMAIN_MAPPING_REQUEST_FAILURE: {
			const { msg } = action;
			return {
				...state,
				isLoading: false,
				error: msg,
			};
		}
		case GET_INSIDER_THREAT_DOMAIN_MAPPING_REQUEST_FAILURE: {
			const { msg } = action;
			return {
				...state,
				isLoading: false,
				error: msg,
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};
