import ThemeOptions from "./ThemeOptions";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import apiCallReducer from "./apiCall";
import rolesReducer from "./entity/roles";
import rolesPageReducer from "./ui/rolesPage";
import usersReducer from "./entity/users";
import usersPageReducer from "./ui/usersPage";
import connectionsReducer from "./entity/connections";
import connectionsPageReducer from "./ui/connectionsPage";
import configurationReducer from "./entity/configuration";
import configurationPageReducer from "./ui/configuratonPage";
import CsvDataReducer from "./entity/csvdata";
import csvDataPageReducer from "./ui/csvDataPage";
import uiCisoManagementReducer from "./ui/cisoManagement";
import entityCisoReducer from "./entity/ciso";
import entityAccountReducer from "./entity/account";
import uiLoginReducer from "./ui/login";
import datapointPageReducer from "./ui/datapointPage";
import datapointReducer from "./entity/datapoint";
import { cyberRiskReducer } from "./cyberRisk/reducers";
import { budgetReducer } from "./budgetProgram//reducers";
import { insiderThreatReducer } from "./insiderThreat/reducers";
import { complianceReducer } from "./compliance/reducers";
import { insightsReducer } from "./insights/reducers";
import { reportsReducer } from "./reports/reducers";
import { notificationPanelReducer } from "./NotificationPanel/reducers";
import { FeatureModuleStatusSelectionsRedcuer } from "./Admin/FeatureEnablerModuleAssignment/reducer";
//operations

import {
  connectionsDataReducer,
  testConnectionReducer,
  deleteConnectionReducer,
  createConnectionReducer,
} from "./Operations/Connection/reducers";
import { AutomationStatusDataReducer } from "./Operations/AutomationStatus/reducers";
import {
  DownloadCyberReducer,
  DownloadCMMCReducer,
  UploadCyberReducer,
  UploadCMMCReducer,
  UploadCMMCAssesmentReducer,
} from "./Operations/CyberCSV/reducers";
import {
  CyberDataPointsReducer,
  CyberTableDataReducer,
  UpdateDataPointsReducer,
} from "./Operations/Periodic/CyberRisk/reducers";
import {
  BudgetDataPointsReducer,
  BudgetTableDataReducer,
} from "./Operations/Periodic/BudgetProgram/reducers";

import {
  InsiderDataPointsReducer,
  InsiderTableDataReducer,
} from "./Operations/Periodic/InsiderThreat/reducers";
import {
  ExecutionModeDetailsRedcuer,
  ExecutionModeSelectionsRedcuer,
} from "./Operations/ExecutionMode/reducers";

import {
  ViewRolesReducer,
  CreateRolesReducer,
  DeleteRolesReducer,
  EditRolesReducer,
} from "./Admin/Roles/reducers";
import {
  ViewUsersReducer,
  CreateUsersReducer,
  DeleteUsersReducer,
  EditUsersReducer,
  DataPointsReducer,
} from "./Admin/User/reducers";
import {
  ViewClientsReducer,
  CreateClientsReducer,
  DeleteClientsReducer,
  EditClientsReducer,
} from "./Admin/Client/reducers";

import {
  CreateModuleAssignmentReducer,
  ModuleStatusSelectionsRedcuer,
} from "./Admin/ModuleAssignment/reducers";

import {
  SignInReducer,
  SendOtpReducer,
  // GenerateOtpReducer,
  generateTOKENReducer,
  // SignUpReducer,
  validateOTPReducer,
  myDetailsReducer,
  resetPasswordReducer,
} from "./Auth/reducers";
import { domainMappingReducer } from "./DomainMapping/reducers";
import { switchRole } from "./SwitchRole/reducers";
import { switchCompany } from "./SwitchCompany/reducers";

import { moduleAssignmentReducer } from "./moduleAssigned/reducers";
import {
  categoryReducer,
  subCategoryReducer,
  metricReducer,
} from "./calibration/reducers";
import { Soc2ComplianceDataReducer } from "./Operations/Periodic/Soc2Compliance/reducers";
import { connectorsReducer } from "./connectors/reducers";

import  riskManagementReducer from './Operations/Risk/reducers';

import jiraTicketReducer from "./cyberRisk/Jira/reducers";

import {
  CMMCDataPointsReducer,
  CMMCTableDataReducer,
  UpdateCMMCDataPointsReducer,
} from "./Operations/Periodic/Cmmc/reducers";

export const history = createBrowserHistory();

export default combineReducers({
  cyberRisk: cyberRiskReducer,
  budget: budgetReducer,
  reoprts: reportsReducer,
  insiderThreat: insiderThreatReducer,
  insights: insightsReducer,
  compliance: complianceReducer,
  domainMapping: domainMappingReducer,
  switchRole,
  switchCompany,
  moduleAssigned: moduleAssignmentReducer,
  ThemeOptions,
  router: connectRouter(history),
  apiCallData: apiCallReducer,
  entity: combineReducers({
    roles: rolesReducer,
    users: usersReducer,
    connections: connectionsReducer,
    configuration: configurationReducer,
    csvData: CsvDataReducer,
    cisoData: entityCisoReducer,
    account: entityAccountReducer,
    datapoint: datapointReducer,
  }),
  ui: combineReducers({
    roles: rolesPageReducer,
    users: usersPageReducer,
    connections: connectionsPageReducer,
    configuration: configurationPageReducer,
    csvData: csvDataPageReducer,
    cisoManagement: uiCisoManagementReducer,
    login: uiLoginReducer,
    datapoint: datapointPageReducer,
  }),
  executionModeDetails: ExecutionModeDetailsRedcuer,
  executionModeSelections: ExecutionModeSelectionsRedcuer,
  connections: connectionsDataReducer,
  testConnectionStatus: testConnectionReducer,
  createConnectionStatus: createConnectionReducer,
  deleteConnectionStatus: deleteConnectionReducer,
  automationStatus: AutomationStatusDataReducer,
  DownloadCyber: DownloadCyberReducer,
  DownloadCmmc: DownloadCMMCReducer,
  UploadCMMC: UploadCMMCReducer,
  UploadCMMCAssesment: UploadCMMCAssesmentReducer,
  UploadCyber: UploadCyberReducer,
  cyberDataPoints: CyberDataPointsReducer,
  cyberTableData: CyberTableDataReducer,
  Soc2ComplianceData: Soc2ComplianceDataReducer,
  updateDataPoints: UpdateDataPointsReducer,
  budgetDataPoints: BudgetDataPointsReducer,
  budgetTableData: BudgetTableDataReducer,
  insiderDataPoints: InsiderDataPointsReducer,
  insiderTableData: InsiderTableDataReducer,

  riskManagement: riskManagementReducer,
  jiraTicket: jiraTicketReducer,
  cmmcDataPoints: CMMCDataPointsReducer,
  cmmcTableData: CMMCTableDataReducer,
  updateCMMCDataPoints: UpdateCMMCDataPointsReducer,

  category: categoryReducer,
  subCategory: subCategoryReducer,
  metric: metricReducer,
  roles: ViewRolesReducer,
  createRoleStatus: CreateRolesReducer,
  deleteRoleStatus: DeleteRolesReducer,
  editRoleStatus: EditRolesReducer,

  users: ViewUsersReducer,
  createUserStatus: CreateUsersReducer,
  deleteUserStatus: DeleteUsersReducer,
  editUserStatus: EditUsersReducer,
  dataPoints: DataPointsReducer,

  clients: ViewClientsReducer,
  createClientStatus: CreateClientsReducer,
  deleteClientStatus: DeleteClientsReducer,
  editClientStatus: EditClientsReducer,
  moduleAssignmentSelections: ModuleStatusSelectionsRedcuer,
  currentUser: SignInReducer,
  GenerateToken: generateTOKENReducer,
  // currentUser: SignUpReducer,
  myDetails: myDetailsReducer,
  SendOtp: SendOtpReducer,
  // GenerateOtp:GenerateOtpReducer,
  validateUserStatus: validateOTPReducer,
  resetPasswordReducer: resetPasswordReducer,
  notificationPanelReducer: notificationPanelReducer,
  FeatureModuleStatusSelectionsRedcuer: FeatureModuleStatusSelectionsRedcuer,
  CreateModuleAssignmentReducer: CreateModuleAssignmentReducer,
  connectorsReducer: connectorsReducer,
  
});
